import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import ReactTable from 'react-table';
import { FaCheckCircle, FaExchangeAlt, FaTimes, FaLanguage } from 'react-icons/fa';
import { MdBlock } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';

import Modal from '../containers/common/Modal';
import DashboardHeader from '../components/DashboardHeader';
import StyledTappable from '../components/common/StyledTappable';
import { PrimaryButton } from '../components/common/Buttons';
import {
  BlackText,
  BoldText,
  RegularText,
  Text,
} from '../components/common/Text';
import {
  disableDoctor,
  enableDoctor,
  masterAdminList,
  transferDoctorToInsurer,
  updateDoctorPhoneNumber,
  updateDoctorTarget,
  updateLanguages,
  transferDoctorToQCInsurer,
} from '../services/admin';
import { debounce } from '../utils';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`;

const OfflineBar = styled.div`
  background: #ec5d57;
  padding: 6px 0;
  color: white;
  text-align: center;
`;

const ReconnectedBar = styled(OfflineBar)`
  background: #56c02c;
`;

const OfflineText = styled(BoldText)`
  font-size: 1.8rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  align-items: stretch;
  overflow-y: hidden;
`;

const Cell = styled(RegularText)`
  color: 'rgba(0,0,0,0.7)';
  margin-right: 8px;
`;

const TabItemContainer = styled(StyledTappable)`
  padding: 10px;
  border-bottom: ${(props) => (props.active ? '3px solid #8852CC' : 'none')};
`;

const TabItemText = styled(Text)`
  font-size: 1.6rem;
  opacity: 0.4;
`;

const ActionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChangeIcon = styled(FaExchangeAlt)`
  margin-right: 8px;
`;

const LanguageIcon = styled(FaLanguage)`
  margin-right: 8px;
  font-size: 24px;
`;

const BlockIcon = styled(MdBlock)`
  margin-right: 8px;
  font-size: 16px;
  color: var(--danger);
`;

const EnabledIcon = styled(FaCheckCircle)`
  margin-right: 8px;
  font-size: 16px;
  color: var(--success);
`;

const ActivityDot = styled(GoPrimitiveDot)`
  font-size: 2rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalBody2 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

const HeaderText = styled(BlackText)`
  font-size: 2.4rem;
  margin: 14px 0;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const insurers = [
  {
    label: 'HEHI',
    value: 'hehi',
  },
  {
    label: 'MAX',
    value: 'max',
  },
  {
    label: 'ABHI',
    value: 'abhi',
  },
  {
    label: 'IPRU',
    value: 'ipru',
  },
  {
    label: 'LIC',
    value: 'lic',
  },
  {
    label: 'AEGON',
    value: 'aegon',
  },
  {
    label: 'ABSLI',
    value: 'absli',
  },
  {
    label: 'ABSLI-VV',
    value: 'absli-vv',
  },
  {
    label: 'RELIANCE',
    value: 'reliance',
  },
  {
    label: 'MCHI',
    value: 'mchi',
  },
  {
    label: 'PPMC',
    value: 'ppmc',
  },
  {
    label: 'Go Digit',
    value: 'godigit',
  },
  {
    label: 'TATA AIA',
    value: 'tata',
  },
  {
    label: 'TATA MER',
    value: 'tata-mer',
  },
  {
    label: 'HDFC',
    value: 'hdfc',
  },
  {
    label: 'KOTAK',
    value: 'kotak',
  },
  {
    label: 'ICICI LOMBARD',
    value: 'icicilbd',
  }
];


const Doctorlanguages = [
  {
    label: 'English',
    value: 'english',
  },
  {
    label: 'Hindi',
    value: 'hindi',
  },
  {
    label: 'Malayalam',
    value: 'malayalam',
  },
  {
    label: 'Tamil',
    value: 'tamil',
  },
  {
    label: 'Kannada',
    value: 'kannada',
  },
  {
    label: 'Telugu',
    value: 'telugu',
  },
  {
    label: 'Marathi',
    value: 'marathi',
  },
  {
    label: 'Punjabi',
    value: 'punjabi',
  },
  {
    label: 'Oriya',
    value: 'oriya',
  },
  {
    label: 'Bengali',
    value: 'bengali',
  },
  {
    label: 'Gujrati',
    value: 'gujrati',
  },
  {
    label: 'Assamese',
    value: 'assamese',
  },
  {
    label: 'Manipuri',
    value: 'manipuri',
  }
];


const CustomFilterInput = ({ filter, onChange }) => (
  <input
    type="text"
    className="form-control"
    placeholder="Search doctor by name"
    id="input-doctor-name"
    value={filter ? filter.value : ''}
    onChange={(ev) => onChange(ev.target.value)}
  />
);

class MasterAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReconnectedBar: false,
      doctorsList: [],
      loading: true,
      transferModalShown: false,
      transferDoctorId: -1,
      selectedInsurer: null,
      transferring: false,
      newPhone: '',
      showUpdatePhoneModal: false,
      selectedDoctor: '',
      updatingNumber: false,
      phoneUpdateSuccess: false,
      showUpdateDailyModal: false,
      dailyTargetSuccess: false,
      newTarget: '',
      updatingTarget: false,
      doctorType: null,
      selectedLanguages: null,
      selectedQCInsurer: null,
      languageModalShown: false,
      QCInsurerModalShown: false,
    };
    this.debouncedFetchDoctors = debounce(this.fetchDoctorsList, 600);
  }

  componentDidMount() {
    if (!this.props.user.authToken) {
      this.props.history.push('/');
      return;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { appState } = this.props;
    const prevAppState = prevProps.appState;
    if (prevAppState === 'OFFLINE' && appState === 'ONLINE') {
      this.setState(
        {
          showReconnectedBar: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              showReconnectedBar: false,
            });
          }, 3000);
        },
      );
    }
  }

  fetchDoctorsList = (state) => {
    const searchTerm =
      state && state.filtered.length > 0 ? state.filtered[0].value : null;
    this.setState({ loading: true });
    masterAdminList(searchTerm)
      .then((res) => {
        this.setState({
          doctorsList: res.result,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
  onQCInsurerSelected = (ele) => {
    this.setState({
      selectedQCInsurer: ele,
    });
  };

  onInsurerSelected = (ele, action) => {
    if (action.action === 'set-value' || action.action === 'select-option') {
      this.setState({
        selectedInsurer: ele.value,
      });
    }
  };

  onLanguageSelected = (ele) => {
    this.setState({
      selectedLanguages: ele,
    });
  };

  transferDoctorToInsurer = () => {
    const { selectedInsurer, transferDoctorId } = this.state;
    this.setState({
      transferring: true,
    });
    transferDoctorToInsurer(transferDoctorId, selectedInsurer)
      .finally(() => {
        this.hideModal();
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  transferDoctorToQCInsurer = () => {
    const { selectedQCInsurer, transferDoctorId } = this.state;
    this.setState({
      transferring: true,
    });

    // Extract only the values from selectedQCInsurers
    const selectedQCValues = selectedQCInsurer.map((insurer) => insurer.value);

    transferDoctorToQCInsurer(transferDoctorId, selectedQCValues)
      .finally(() => {
        this.hideModal();
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateLanguages = () => {
    const { selectedLanguages, transferDoctorId } = this.state;
    // Extract only the values from selectedLanguages
    const selectedLanguageValues = selectedLanguages.map(
      (language) => language.value,
    );
    this.setState({
      transferring: true,
    });
    updateLanguages(transferDoctorId, selectedLanguageValues)
      .finally(() => {
        this.hideModal();
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  disableAccount = (doctorId) => () => {
    disableDoctor(doctorId)
      .finally(() => {
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  enableAccount = (doctorId) => () => {
    enableDoctor(doctorId)
      .finally(() => {
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      transferDoctorId: -1,
      selectedInsurer: null,
      transferModalShown: false,
      transferring: false,
      selectedDoctor: '',
      newPhone: '',
      showUpdatePhoneModal: false,
      updatingNumber: false,
      phoneUpdateSuccess: false,
      showUpdateDailyModal: false,
      dailyTargetSuccess: false,
      newTarget: '',
      updatingTarget: false,
      doctorType: null,
      selectedLanguages: null,
      selectedQCInsurer: null,
      languageModalShown: false,
      QCInsurerModalShown: false,
    });
  };

  confirmTransfer = (doctorId) => () => {
    this.setState({
      transferModalShown: true,
      transferDoctorId: doctorId,
    });
  };

  confirmQCTransfer =
    ({ id, qcInsurers }) =>
      () => {
        const matchedInsurers =
          qcInsurers.length > 0
            ? insurers.filter((insurer) => qcInsurers.includes(insurer.value))
            : [];

        this.setState({
          QCInsurerModalShown: true,
          transferDoctorId: id,
          selectedQCInsurer: matchedInsurers,
        });
      };

  setLanguages =
    ({ id, languages }) =>
      () => {
        const matchedLanguages =
          languages.length > 0
            ? Doctorlanguages.filter((lang) => languages.includes(lang.value))
            : [];
        this.setState({
          languageModalShown: true,
          transferDoctorId: id,
          selectedLanguages: matchedLanguages,
        });
      };

  openUpdatePhoneModal = (doctor) => () => {
    this.setState({
      newPhone: doctor.phone,
      showUpdatePhoneModal: true,
      selectedDoctor: doctor,
      updatingNumber: false,
      phoneUpdateSuccess: false,
    });
  };

  openUpdateDoctorTarget = (doctor) => () => {
    this.setState({
      showUpdateDailyModal: true,
      updatingTarget: false,
      selectedDoctor: doctor,
      newTarget: doctor.target,
    });
  };

  updateTarget = () => {
    this.setState({
      updatingTarget: true,
    });
    const { newTarget, selectedDoctor } = this.state;
    const body = {
      doctorId: selectedDoctor.id,
      newTarget,
    };
    updateDoctorTarget(body)
      .then((res) => {
        this.setState({
          dailyTargetSuccess: true,
        });
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          updatingTarget: false,
        });
      });
  };

  updatePhone = () => {
    this.setState({
      updatingNumber: true,
    });
    const { newPhone, selectedDoctor } = this.state;
    const body = {
      doctorId: selectedDoctor.id,
      newPhone,
      oldPhone: selectedDoctor.phone,
    };
    updateDoctorPhoneNumber(body)
      .then((res) => {
        this.setState({
          phoneUpdateSuccess: true,
        });
        this.fetchDoctorsList();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          updatingNumber: false,
        });
      });
  };

  render() {
    const { appState, user } = this.props;
    const {
      showReconnectedBar,
      doctorsList,
      loading,
      transferModalShown,
      transferring,
      selectedInsurer,
      showUpdatePhoneModal,
      selectedDoctor,
      newPhone,
      updatingNumber,
      phoneUpdateSuccess,
      showUpdateDailyModal,
      dailyTargetSuccess,
      newTarget,
      updatingTarget,
      doctorType,
      selectedLanguages,
      selectedQCInsurer,
      languageModalShown,
      QCInsurerModalShown,
    } = this.state;
    const ActionCell = ({ original }) => {
      return (
        <>
          {original.qc ? (
            <ActionContainer onTap={this.confirmQCTransfer(original)}>
              <ChangeIcon />
              <Cell as="span">Change QC Insurer</Cell>
            </ActionContainer>
          ) : ''}
          <ActionContainer onTap={this.confirmTransfer(original.id)}>
            <ChangeIcon />
            <Cell as="span">Change Insurer</Cell>
          </ActionContainer>
          <ActionContainer onTap={this.setLanguages(original)}>
            <LanguageIcon />
            <Cell as="span">Update Language</Cell>
          </ActionContainer>
          {original.disabled ? (
            <ActionContainer onTap={this.enableAccount(original.id)}>
              <EnabledIcon />
              <Cell as="span">Enable</Cell>
            </ActionContainer>
          ) : (
            <ActionContainer onTap={this.disableAccount(original.id)}>
              <BlockIcon />
              <Cell as="span">Disable</Cell>
            </ActionContainer>
          )}
          <ActionContainer onTap={this.openUpdatePhoneModal(original)}>
            <ChangeIcon />
            <Cell as="span">Update Phone No</Cell>
          </ActionContainer>
          {user.updateTarget ? (
            <ActionContainer onTap={this.openUpdateDoctorTarget(original)}>
              <ChangeIcon />
              <Cell as="span">Update Monthly Target</Cell>
            </ActionContainer>
          ) : (
            ''
          )}
        </>
      );
    };
    const DoctorNameHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Name</TabItemText>
        </TabItemContainer>
      );
    };
    const ActionsHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Actions</TabItemText>
        </TabItemContainer>
      );
    };
    const InsurerHeader = () => {
      return (
        <TabItemContainer>
          <TabItemText>Insurer</TabItemText>
        </TabItemContainer>
      );
    };
    const TableCell = (props) => {
      return <Cell>{props.value}</Cell>;
    };
    const columns = [
      {
        Header: '',
        Cell: ({ original }) => {
          if (original.active) {
            return <ActivityDot className="text-success" />;
          }
          return <ActivityDot className="text-danger" />;
        },
        style: {
          'text-align': 'center',
        },
        maxWidth: 80,
      },
      {
        Header: DoctorNameHeader,
        Cell: TableCell,
        accessor: 'name',
        filterable: true,
        Filter: CustomFilterInput,
        style: {
          'text-align': 'center',
        },
      },
      {
        Header: InsurerHeader,
        Cell: ({ original }) => {
          if (original.type === 'n-mbbs') {
            return <Cell>HEHI</Cell>;
          }
          return <Cell>{(original.insurer || '').toUpperCase()}</Cell>;
        },
        style: {
          'text-align': 'center',
        },
      },
      {
        Header: <ActionsHeader />,
        Cell: ActionCell,
        style: {
          'text-align': 'center',
        },
      },
    ];

    return (
      <OuterContainer>
        {appState === 'OFFLINE' && (
          <OfflineBar>
            <OfflineText>You are offline</OfflineText>
          </OfflineBar>
        )}
        {showReconnectedBar && (
          <ReconnectedBar>
            <OfflineText>Reconnecting...</OfflineText>
          </ReconnectedBar>
        )}
        <DashboardHeader history={this.props.history} />
        <BodyContainer>
          <ReactTable
            data={doctorsList}
            loading={loading}
            columns={columns}
            style={{
              flex: '1 0',
            }}
            manual
            onFetchData={this.debouncedFetchDoctors}
          />
        </BodyContainer>
        {QCInsurerModalShown && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select the insurers to which you would like to transfer the doctors.`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <Select
                    options={insurers}
                    onChange={this.onQCInsurerSelected}
                    isDisabled={transferring}
                    className="select-options"
                    classNamePrefix="react-select"
                    isMulti
                    isSearchable
                    value={selectedQCInsurer}
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.transferDoctorToQCInsurer}
                      disabled={!selectedQCInsurer}
                      loading={transferring}
                    >
                      <Text fontSize="16px" color="white">
                        Transfer
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {transferModalShown && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select the insurers to which you would like to transfer the doctors.`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <Select
                    options={insurers}
                    onChange={this.onInsurerSelected}
                    isDisabled={transferring}
                    className="select-options"
                    classNamePrefix="react-select"
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.transferDoctorToInsurer}
                      disabled={!selectedInsurer}
                      loading={transferring}
                    >
                      <Text fontSize="16px" color="white">
                        Transfer
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {languageModalShown && (
          <Modal>
            <ModalContainer>
              <ModalBody>
                <ModalHeader>
                  <HeaderText>
                    {`Please select the languages for doctors`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body>
                  <Select
                    options={Doctorlanguages}
                    onChange={this.onLanguageSelected}
                    isDisabled={transferring}
                    className="select-options"
                    isMulti
                    classNamePrefix="react-select"
                    value={selectedLanguages}
                    isSearchable
                  />
                  <ButtonComponent>
                    <Button
                      onTap={this.updateLanguages}
                      disabled={!selectedLanguages}
                      loading={transferring}
                    >
                      <Text fontSize="16px" color="white">
                        Update
                      </Text>
                    </Button>
                  </ButtonComponent>
                </Body>
              </ModalBody>
            </ModalContainer>
          </Modal>
        )}
        {showUpdatePhoneModal && (
          <Modal>
            <ModalContainer>
              <ModalBody2>
                <ModalHeader>
                  <HeaderText>
                    {`Update Phone Number for ${selectedDoctor.name
                      } (Current: ${phoneUpdateSuccess ? newPhone : selectedDoctor.phone
                      })`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body
                  style={{
                    width: '50%',
                    margin: 'auto',
                  }}
                >
                  {phoneUpdateSuccess ? (
                    <HeaderText>{`Updated Successfully`}</HeaderText>
                  ) : (
                    <>
                      <input
                        className="form-control border "
                        type="text"
                        value={newPhone}
                        onChange={(e) => {
                          this.setState({
                            newPhone: e.target.value,
                          });
                        }}
                        id="updated-phone-input"
                      />
                      <PrimaryButton
                        loading={updatingNumber}
                        onTap={this.updatePhone}
                        disabled={String(newPhone).length < 9}
                        className="mt-3 mr-2"
                      >
                        <Text fontSize="16px" color="white">
                          Update number
                        </Text>
                      </PrimaryButton>
                    </>
                  )}
                </Body>
              </ModalBody2>
            </ModalContainer>
          </Modal>
        )}
        {showUpdateDailyModal && (
          <Modal>
            <ModalContainer>
              <ModalBody2>
                <ModalHeader>
                  <HeaderText>
                    {`Update Monthly Target  for ${selectedDoctor.name
                      } (Current: ${dailyTargetSuccess
                        ? newTarget
                        : selectedDoctor.dailyTarget || 'Not set'
                      })`}
                  </HeaderText>
                  <ButtonsComponent>
                    <CloseContainer onTap={this.hideModal}>
                      <CrossIcon />
                    </CloseContainer>
                  </ButtonsComponent>
                </ModalHeader>

                <Body
                  style={{
                    width: '50%',
                    margin: 'auto',
                  }}
                >
                  {dailyTargetSuccess ? (
                    <HeaderText>{`Updated Successfully`}</HeaderText>
                  ) : (
                    <>
                      <input
                        className="form-control border "
                        type="text"
                        value={newTarget}
                        onChange={(e) => {
                          this.setState({
                            newTarget: e.target.value,
                          });
                        }}
                        id="updated-target-input"
                      />
                      <PrimaryButton
                        loading={updatingTarget}
                        onTap={this.updateTarget}
                        disabled={!newTarget || String(newTarget).length < 1}
                        className="mt-3 mr-2"
                      >
                        <Text fontSize="16px" color="white">
                          Update Target
                        </Text>
                      </PrimaryButton>
                    </>
                  )}
                </Body>
              </ModalBody2>
            </ModalContainer>
          </Modal>
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.app.appState,
});

export default connect(mapStateToProps)(MasterAdmin);
//  /* (Current: {phoneUpdateSuccess ? newPhone : selectedDoctor.phone}) */
