import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import config from '../config';
import { useDyteClient, DyteProvider } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import { joinCall, joinLog, videoScreenshot } from '../services';
import { connect } from 'react-redux';
import Modal from '../containers/common/Modal';
import { message } from 'antd';
import styled from 'styled-components';
import { BoldText, RegularText } from '../components/common/Text';
import StyledTappable from '../components/common/StyledTappable';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from './common/Buttons';
import { RiScreenshot2Fill } from 'react-icons/ri';
import Select from 'react-select';
import {
  videoScreenshotForRelative,
  scanPatient,
  recordTimestamp,
} from '../services';
import moment from 'moment';
import DyteVideoBackgroundTransformer from '@dytesdk/video-background-transformer';
// import backgroundModule from '@dytesdk/background-changer-module';
// import { createAndGetModule } from '@dytesdk/background-changer-module';

function DyteVideoContainer(props) {
  // const { meeting } = useDyteMeeting();

  const photoId = 'photoid';
  const fullId = 'full';
  const doctor = 'doctor';

  const CameraIcon = styled(RiScreenshot2Fill)`
    width: 3rem;
    height: 3rem;
  `;

  const CloseContainer = styled(StyledTappable)`
    margin-bottom: 20rem;
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #dadada;
    z-index: 110;
    position: absolute;
    bottom: 0;
    left: 50%;
  `;

  const CloseContainerModal = styled(StyledTappable)`
    align-self: flex-end;
  `;

  const ModalContainer = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  `;

  const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    max-width: 400px;
    padding: 1.8rem 2rem 3rem 2rem;
    border-radius: 0.8rem;
    margin: 0 1.6rem;
    text-align: center;
  `;

  const ModalIcon = styled.img`
    width: 9.1rem;
  `;

  const ModalText = styled(RegularText)`
    font-size: 1.6rem;
  `;

  const ModalTitle = styled(BoldText)`
    font-size: 1.1rem;
    margin: 0.625rem 0rem;
  `;

  const PreviewButton = styled(PrimaryButton)`
    float: left;
    color: white;
    font-size: 14px;
  `;

  const Screenshot = styled(StyledTappable)`
    padding: 5px;
    height: 5rem;
    border-radius: 5rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    font-weight: bold;
    z-index: 110;
    position: absolute;
    top: 10%;
    left: 65%;
  `;

  const ScanBlock = styled(StyledTappable)`
    padding: 5px;
    height: 5rem;
    border-radius: 5rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    font-weight: bold;
    z-index: 110;
    position: absolute;
    top: 10%;
    left: 65%;
  `;

  const CloseIcon = styled(FaTimes)`
    width: 3rem;
    height: 3rem;
  `;
  const SCAN_TIME = 20;

  const [roomName, setRoomName] = useState('');
  const [popUp, setPopUp] = useState(false);

  const [selectedRelative, setSelectedRelative] = useState(null);
  const [clientSpecificId, setClientSpecificId] = useState('');
  const [loading, setLoading] = useState(false);
  const [meeting, initMeeting] = useDyteClient();
  const [timestamps, setTimestamps] = useState([moment()]);
  const [showScanBlock, setShowScanBlock] = useState(true);
  const [scanTime, setScanTime] = useState(SCAN_TIME);
  // const [iframeCheck, setIframeCheck] = useState(false);

  useEffect(() => {
    joinCall(props.callId)
      .then((res) => {
        const data = new Object();

        data.roomName = res.roomName;
        data.token = res.token;
        data.callId = props.callId;
        data.clientSpecificId = res.clientSpecificId;

        setClientSpecificId(res.clientSpecificId);
        setRoomName(res.roomName);

        if (res.data === 'Doctor already joined') {
          console.log('Doc joined');
          //update error state
        }
        return data;
      })
      .then((data) => {
        const body = {
          callId: data.callId,
          userType: 'doctor',
          connectionId: data.clientSpecificId,
          doctorId: props.doctorId,
        };
        joinLog(body)
          .then((res) => {
            console.log('Response from join call -200');
          })
          .catch((err) => {
            console.log('Response from join call API- ERR', err);
          });
        return data;
      })
      .then((data) => {
        console.log(data.token);
        return initMeeting({
          authToken: data.token,
          // set default values for user media
          defaults: {
            audio: true,
            video: true,
          },
        });
      });
  }, []);

  const addBlurBackground = async () => {
    const videoBackgroundTransformer =
      await DyteVideoBackgroundTransformer.init();
    const videoMiddleware =
      await videoBackgroundTransformer.createStaticBackgroundVideoMiddleware(
        `https://mer-doctor.getvisitapp.com/5bb3c4687ce160fa99b34e825ee14ea767e96bef-1316x720.jpeg`,
      );
    meeting.self.addVideoMiddleware(videoMiddleware);
  };

  useEffect(() => {
    if (meeting && meeting) {
      console.log(meeting, meeting.uiConfig);
      // addBlurBackground();
      // meeting.updateUIConfig({
      //   controlBar: false,
      // });
    }
  }, [meeting]);

  const modalView = () => {
    setPopUp(true);
  };

  const hideView = () => {
    setPopUp(false);
  };

  const scanPatientCall = (userId, callId) => {
    //const userId = activeConsult.user.userId;

    // scanHideView();
    /// setIframeCheck(true);
    scanPatient(userId, callId)
      .then((result) => {
        console.log('scan pat api', result);
        message.success('Sent for facial scan');
        takeScreenshot(callId, 'scan');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // scanHideView();
      });
  };

  useEffect(() => {
    if (!showScanBlock) {
      const interval = setInterval(
        () =>
          setScanTime((prev) => {
            if (prev === 0) {
              return SCAN_TIME;
            }
            return --prev;
          }),
        1000,
      );
      return () => clearInterval(interval);
    }
  }, [showScanBlock]);

  const scanTimeStamp = (consultationId) => {
    setShowScanBlock(false);
    setTimestamps((prev) => prev.push(moment()));

    const scanTimestampTimeout = setTimeout(() => {
      const finalTimeStamps = [...timestamps, moment()];

      recordTimestamp(consultationId, finalTimeStamps)
        .then((res) => {
          message.success('Timestamps saved successfully');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // scanHideView();
          setTimestamps([timestamps[0]]);
          setShowScanBlock(true);
          setScanTime(SCAN_TIME);
        });
    }, scanTime * 1000); // 20 seconds
    // return clearTimeout(scanTimestampTimeout)
    return () => clearTimeout(scanTimestampTimeout);
  };

  const dataURItoBlob = (dataURI) => {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const selectRelative = (val) => {
    setSelectedRelative(val.value);
  };

  const base64StringBlobExtract = (type) => {
    const videoTrack =
      type === doctor
        ? meeting.self.localMediaHandler.videoTrack
        : meeting.participants.active.toArray()[0].videoTrack;
    if (videoTrack) {
      const imageCapture = new ImageCapture(videoTrack);
      const c = document.createElement('canvas');
      const ctx = c.getContext('2d');
      return imageCapture
        .grabFrame()
        .then((imageMap) => {
          c.height = imageMap.height;
          c.width = imageMap.width;
          ctx.drawImage(imageMap, 0, 0, c.width, c.height);
          const base64StringData = c.toDataURL(); //toDataUrl("image/png");
          let blob = dataURItoBlob(base64StringData);

          // for not showing doctor image screenshot
          if (type === doctor) return blob;
          //Setting image Block
          const img = document.querySelector('#screenshot');
          img.src = base64StringData;
          img.style.display = 'block';

          return blob;
        })
        .catch((err) => {
          console.log('Error in grabing Frame');

          //err.message = 'Error in Grabing Frame from Video Track';
          throw err;
        });
    } else {
      return false;
    }
  };

  async function takeScreenshot(callId, type) {
    setLoading(true);
    try {
      let blobUser = await base64StringBlobExtract(type);
      let blobDoctor = null;
      if (type === photoId && props.insurer === 'ppmc') {
        blobDoctor = await base64StringBlobExtract(doctor);
      }

      if (blobUser) {
        let formData = new FormData();
        formData.append('file', blobUser);

        if (blobUser && blobDoctor) {
          const form = new FormData();
          form.append('file', blobDoctor);
          await videoScreenshot(form, callId, doctor);
        }

        videoScreenshot(formData, callId, type)
          .then((result) => {
            console.log('entered');
            setPopUp(false);
            // setSelectedRelative('');
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setTimeout(() => {
              const img = document.querySelector('#screenshot');
              if (img) {
                img.style.display = 'none';
                img.src = null;
              }
            }, 5000);
          });
      } else {
        const err = new Error();
        err.message =
          'FAILED - VIDEO TRACK NOT FOUND WHILE UPLOADIN SCREENSHOT DYTE PROVIDER ';
        console.log(err);
        throw err;
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  async function takeScreenshotFromPopUp(relative) {
    setLoading(true);
    let blob = await base64StringBlobExtract();

    if (blob) {
      let formData = new FormData();
      formData.append('file', blob);
      videoScreenshotForRelative(formData, relative)
        .then((result) => {
          setPopUp(false);
          setSelectedRelative('');
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            const img = document.querySelector('#screenshot');
            if (img) {
              img.style.display = 'none';
              img.src = null;
            }
          }, 5000);
        });
    } else {
      const err = new Error();
      err.message =
        'FAILED - VIDEO TRACK NOT FOUND WHILE UPLOADIN SCREENSHOT DYTE PROVIDER ';
      console.log(err);
      throw err;
    }

    setLoading(false);
  }
  /*
      async function takeScreenshotFromPopUp(relative) {
        setLoading(true);
        const videoTrack = meeting.participants.active.toArray()[0].videoTrack;
        if (videoTrack) {
          const imageCapture = new ImageCapture(videoTrack);
          const c = document.createElement('canvas');
          const ctx = c.getContext('2d');
          imageCapture.grabFrame().then((imageMap) => {
            c.height = imageMap.height;
            c.width = imageMap.width;
            ctx.drawImage(imageMap, 0, 0, c.width, c.height);
            const base64String = c.toDataURL(); //toDataUrl("image/png");
    
            const img = document.querySelector('#screenshot');
            img.src = 'data:image/png;base64,' + base64String;
            img.style.display = 'block';
    
            let blob = dataURItoBlob(base64String);
            let formData = new FormData();
            formData.append('file', blob);
            videoScreenshotForRelative(formData, relative)
              .then((result) => {
                console.log('entered');
                setPopUp(false);
                setSelectedRelative('');
              })
              .catch((err) => {
                console.log(err);
              }).finally(() => {
                setLoading(false);
                setTimeout(() => {
                  const img = document.querySelector('#screenshot');
                  if (img) {
                    img.style.display = 'none';
                    img.src = null;
                  }
                }, 5000);
              });
          });
        } else {
          const err = new Error();
          err.message =
            'FAILED - VIDEO TRACK NOT FOUND WHILE UPLOADIN SCREENSHOT DYTE PROVIDER ';
          console.log(err);
          throw err;
        }
        setLoading(false);
      }
      */

  /*
    async function screenshot() {
      const videoTrack = meeting.participants.active.toArray()[0].videoTrack;
      if (videoTrack) {
        const imageCapture = new ImageCapture(videoTrack);
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        imageCapture.grabFrame().then((imageMap) => {
          c.height = imageMap.height;
          c.width = imageMap.width;
          ctx.drawImage(imageMap, 0, 0, c.width, c.height);
          const base64String = c.toDataURL(); //toDataUrl("image/png");
          console.log('gframe', base64String);
        });
        // console.log('ytp', tp, 'ygf', gf);
      }


      //1. this converts base64 to a blob
      const dataURItoBlob = (dataURI) => {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
        else byteString = unescape(dataURI.split(',')[1]);

        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
      };

      //taking div dom by id 
      const meetingSection = document.getElementById('meeting');

      html2canvas(meetingSection).then(function (canvas) {
        const base64StringData = canvas.toDataURL('image/png'); //saving in base 64 format 
        
        //to show image
        const img = document.querySelector('#screenshot');
        img.src = base64StringData;
        img.style.display = 'block';

        //hide image after sometime(5 secs)
        setTimeout(() => {
          const img = document.querySelector('#screenshot');
          if (img) {
            img.style.display = 'none';
            img.src = null;
          }
        }, 5000);

        //change base64 to blob
        let blob = dataURItoBlob(base64StringData);

        //blob to file so that we can send it to an api
        let formData = new FormData();
        formData.append('file', blob);

        //call api and pass file
        apiCall(file).then(res => {console.log('scucess')}).catch(err=>console.log(err));


        console.log('Screenshot Taken and saved to URL ', frame);
      }).catch(err => {
        console.log('Erorr in takin ss/html2canvas', err);
      });
    }*/

  return (
    <div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Screenshot
          onTap={() => {
            if (props.insurer === 'abhi') {
              modalView();
            } else {
              takeScreenshot(props.callId, photoId);
            }
          }}
        >
          <CameraIcon />
          <br />
          <p
            style={{
              margin: ' 0 10px',
              fontSize: '14px',
            }}
          >
            Photo Id
          </p>
        </Screenshot>
      </div>
      {props.insurer === 'aegon' ||
        props.insurer === 'lic' ||
        props.insurer === 'absli' ||
        props.insurer === 'absli-vv' ||
        props.insurer === 'tata-mer' ? (
        <Screenshot
          style={{ marginLeft: '14px', left: '40%' }}
          onTap={() => takeScreenshot(props.callId, fullId)}
        >
          <CameraIcon />
          <br />
          <p
            style={{
              margin: ' 0 10px',
              fontSize: '14px',
            }}
          >
            Full Length
          </p>
        </Screenshot>
      ) : (
        ''
      )}

      {showScanBlock &&
        (props.insurer === 'absli' ||
          props.insurer === 'ppmc' ||
          props.insurer === 'aegon') ? (
        <ScanBlock
          style={{ marginLeft: '14px', left: '20%' }}
          onTap={() =>
            props.insurer === 'aegon'
              ? scanTimeStamp(props.activeConsult.consultationId)
              : scanPatientCall(props.activeConsult.user.userId, props.callId)
          }
        >
          <CameraIcon />
          <br />
          <p
            style={{
              margin: ' 0 10px',
              fontSize: '14px',
            }}
          >
            Scan Patient
          </p>
        </ScanBlock>
      ) : props.insurer === 'aegon' ? (
        <ScanBlock style={{ marginLeft: '14px', left: '20%' }}>
          {scanTime} Sec
        </ScanBlock>
      ) : (
        ''
      )}

      {popUp && (
        <Modal>
          <ModalContainer>
            <ModalBody>
              <CloseContainerModal
                onTap={() => {
                  hideView();
                }}
              >
                <FaTimes
                  onTap={() => {
                    hideView();
                  }}
                />
              </CloseContainerModal>
              <ModalTitle>Select Relative to save screenshot for.</ModalTitle>
              <Select
                options={props.activeConsult.relatives.map((ele) => ({
                  label: ele.name,
                  value: ele.relativeId,
                }))}
                classNamePrefix="react-select"
                onChange={selectRelative}
                className="mt-2 select-options relative-select"
              />
              <PreviewButton
                loading={loading}
                disabled={!selectedRelative}
                onTap={() => takeScreenshotFromPopUp(selectedRelative)}
                className="mt-3"
              >
                <CameraIcon /> Click And Save.{' '}
              </PreviewButton>
            </ModalBody>
          </ModalContainer>
        </Modal>
      )}

      <div id="meeting">
        <DyteProvider value={meeting}>
          <div style={{ height: '90vh', minHeight: '600px', width: '100%' }}>
            {/*<DyteMeeting mode="fill" meeting={meeting} />*/}
            {/*  <DyteMeeting mode='fill' clientId={clientSpecificId}
              onInit={(meeting) => {
                console.log('bg module active', backgroundModule);
                meeting.modules.initAndAdd(backgroundModule);
              }} /> */}

            <DyteMeeting mode="fill" meeting={meeting} />
            <img src={null} id="screenshot" alt="img"></img>
          </div>
        </DyteProvider>
      </div>

      <CloseContainer
        onTap={() => {
          if (meeting) {
            meeting.leaveRoom();
          }
          props.stopCall();
        }}
      >
        <CloseIcon />
      </CloseContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    callId: state.consults.activeConsult.callId,
    userStatus: state.consults.userStatus,
    insurer: state.user.insurer,
    activeConsult: state.consults.activeConsult,
    doctorId: state.user.doctorId,
  };
};

export default connect(mapStateToProps)(DyteVideoContainer);
