import axios from 'axios';
import config from '../config';
import { store } from '../store';
import { showError } from '../utils';
import { getBaseUrlForInsurer } from './utils';

const standardErrorPromise = (err) => {
  showError(err);
  throw err;
};

export function adminConsole(oneConnect = false) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/consultations/admin?oneConnect=${oneConnect}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    });
}

export function getTransferList() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const insurer = user.insurer;

  return axios
    .get(
      `${config.apiBaseUrl}/consultations/transfer-list?insurer=${insurer}`,
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    });
}

export function transferConsult(doctorId) {
  const { user, consults } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(
      `${config.apiBaseUrl}/consultations/transfer`,
      {
        consultationId: consults.activeConsult.consultationId,
        transferTo: doctorId,
        transferFrom: consults.activeConsult.doctorId,
      },
      {
        headers,
      },
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    });
}

export function checkArmsoftData(applicationNo) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${getBaseUrlForInsurer()}/check-armsoft-data`, applicationNo, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'warning'
      ) {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.response = responseBody;
      throw error;
    });
}

export function getArmsoftData(applicationNo) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    applicationNo,
  };

  return axios
    .post(`${getBaseUrlForInsurer()}/get-armsoft-data`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'warning'
      ) {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.response = responseBody;
      throw error;
    });
}

export function createNewCaseArmsoft(
  applicationNo,
  attempt,
  asser,
  case_type,
  reason_referral,
  remark,
  first_status,
  contactibility,
  mars_filled,
  mars_comment,
  nriOrNonNri,
  videoOrTele,
  video_capturing,
  number_of_attempt,
  adversity_flag,
  remarks_adversity,
  updateChecker,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const formData = new FormData();
  formData.append('applicationNo', applicationNo);
  formData.append('attempt', attempt);
  formData.append('asser', asser);
  formData.append('remarks_adversity', remarks_adversity);
  formData.append('case_type', case_type);
  formData.append('reason_referral', reason_referral);
  formData.append('remark', remark);
  formData.append('first_Status', first_status);
  formData.append('contactibility', contactibility);
  formData.append('mars_filled', mars_filled);
  formData.append('mars_comment', mars_comment);
  formData.append('nriOrNonNri', nriOrNonNri);
  formData.append('videoOrTele', videoOrTele);
  formData.append('video_capturing', video_capturing);
  formData.append('updateChecker', updateChecker);
  if (adversity_flag != undefined && number_of_attempt != undefined) {
    formData.append('adversity_flag', adversity_flag);
    formData.append('number_of_attempt', number_of_attempt);
  }

  return axios
    .post(`${getBaseUrlForInsurer()}/case-creation`, formData, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'warning'
      ) {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.response = responseBody;
      throw error;
    });
}
export function createNewDoctor(
  doctorName,
  doctorQualification,
  doctorPhone,
  registrationNumber,
  password,
  insurer,
  signature,
  qc,
  admin,
  language,
) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };
  const formData = new FormData();
  formData.append('doctorName', doctorName);
  formData.append('doctorQualification', doctorQualification);
  formData.append('doctorPhone', doctorPhone);
  formData.append('registrationNumber', registrationNumber);
  formData.append('password', password);
  formData.append('insurer', insurer);
  formData.append('language', language);
  if (signature) {
    formData.append('file', signature);
  }
  formData.append('qc', qc);
  if (admin) {
    formData.append('admin', admin);
  }
  return axios
    .post(`${config.apiBaseUrl}/doctor-auth/doctor`, formData, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (
        responseBody.message === 'success' ||
        responseBody.message === 'warning'
      ) {
        return responseBody;
      }
      const error = new Error('Invalid response');
      error.response = responseBody;
      throw error;
    });
}

export function masterAdminList(search) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/admin/list`, {
      headers,
      params: {
        search,
      },
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function transferDoctorToInsurer(doctorId, insurer) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
    insurer,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/change-insurer`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function disableDoctor(doctorId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/disable-doctor`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function enableDoctor(doctorId) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/enable-doctor`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function updateDoctorTarget(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  console.log('the body is ', body);

  return axios
    .post(`${config.apiBaseUrl}/consultations/update-target`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function updateDoctorPhoneNumber(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/consultations/update-phone`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function getQcCaseCount() {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .get(`${config.apiBaseUrl}/admin/get-qc-cases-count`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getQcPendingCaseDays() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const url = `${config.apiBaseUrl}/admin/qc-pending-days`;
  return axios
    .get(url, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getClosurePercent() {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .get(`${getBaseUrlForInsurer()}/completed-case-percentage`, {
      headers,
      params: {},
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function markRecordingViewInitiated(body) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/set-view-recording-time`, body, {
      headers,
    })
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getNewQcCases() {
  const state = store.getState();
  const { authToken } = state.user;
  const headers = {
    authorization: authToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/consultations/qc-case-assignment`, null, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function updateLanguages(doctorId, languages) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
    languages,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/edit-qc-doctor-languages`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}

export function transferDoctorToQCInsurer(doctorId, qcInsurers) {
  const { user } = store.getState();
  const headers = {
    authorization: user.authToken,
  };

  const body = {
    doctorId,
    qcInsurers,
  };

  return axios
    .post(`${config.apiBaseUrl}/admin/edit-qc-doctor-insurers`, body, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      // Invalid response
      const error = new Error('Invalid response');
      throw error;
    })
    .catch(standardErrorPromise);
}