import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';
import Modal from '../../containers/common/Modal';
import StyledTappable from '../common/StyledTappable';
import { BoldText } from '../common/Text';
import { FaTimes } from 'react-icons/fa';
import { PrimaryButton } from '../common/Buttons';
import {
  getTransferList,
  transferConsult,
  transferCases,
} from '../../services';
import { showError } from '../../utils';
import { fetchConsults } from '../../actions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  display: flex;
  flex: 1;
  max-width: 70%;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;

const CloseContainer = styled(StyledTappable)`
  /* align-self: flex-end; */
`;

const CrossIcon = styled(FaTimes)`
  font-size: 2rem;
  font-weight: normal;
`;

const ButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.625rem;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(PrimaryButton)`
  margin-left: 0.625rem;
  /* width: 6rem; */
  margin-top: 1.625rem;
`;

const ButtonText = styled(BoldText)`
  color: white;
  font-size: 1.5rem;
  padding: 0.625rem 1.625rem;
  /* text-transform: uppercase; */
  text-align: center;
`;

const ModalHeader = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding: 1.825rem 1.25rem 1.875rem 1.25rem;
`;

const HeaderText = styled(BoldText)`
  font-size: 2rem;
`;

const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  /* border: 4mm solid rgb(170, 50, 20, 0.6); */
  flex-direction: column;
`;

class CloseConsultationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      doctorsList: [],
      selectedDoctorIndex: -1,
      loading: false,
    };
  }

  componentDidMount() {
    const { doctorType } = this.props;
    if (doctorType === 'n-mbbs') {
      getTransferList()
        .then((data) => {
          this.setState({ doctorsList: data.doctors });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  transferConsultation = () => {
    this.setState({ loading: true });
    const { selectedDoctorIndex } = this.state;
    const { authToken, activeFilterName, dispatch, doctorType, userId, selectedStateFilter } =
      this.props;
    let countryState;
    if(selectedStateFilter){
      countryState = selectedStateFilter.value;
    }
    if (doctorType === 'n-mbbs') {
      transferConsult(selectedDoctorIndex)
        .then(() => {
          dispatch(
            fetchConsults({
              authToken,
              filters: [
                { name: activeFilterName, isSelected: true },
                ...(countryState ? [{ name: 'STATE', isSelected: true, value: countryState }] : [])
              ],
            }),
          );
          this.props.hideModal();
        })
        .catch((err) => {
          console.log(err);
          showError(err);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      transferCases([userId], selectedDoctorIndex)
        .then(() => {
          dispatch(
            fetchConsults({
              authToken,
              filters: [
                { name: activeFilterName, isSelected: true },
                ...(countryState ? [{ name: 'STATE',isSelected: true, value: countryState }] : [])
              ],
            }),
          );
          this.props.hideModal();
        })
        .catch((err) => {
          console.log(err);
          showError(err);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  };

  onDoctorSelected = (val, action) => {
    if (action.action === 'select-option') {
      this.setState({
        selectedDoctorIndex: val.value,
      });
    }
  };

  render() {
    const { doctorsList, selectedDoctorIndex } = this.state;
    const { labels, doctorType } = this.props;
    const transferDoctorsList = this.props.doctorsList;
    return (
      <Modal>
        <ModalContainer>
          <ModalBody>
            <ModalHeader>
              <HeaderText>
                {`Please select a doctor to transfer the consult to(from ${labels[0].text})`}
              </HeaderText>
              <ButtonsComponent>
                <CloseContainer onTap={this.props.hideModal}>
                  <CrossIcon />
                </CloseContainer>
              </ButtonsComponent>
            </ModalHeader>

            <Body>
              {doctorType === 'n-mbbs' ? (
                <Select
                  options={doctorsList.map((ele) => ({
                    value: ele.id,
                    label: `${ele.name}(${ele.activeConsults} active consults)`,
                  }))}
                  onChange={this.onDoctorSelected}
                  isDisabled={this.state.loading}
                  classNamePrefix="react-select"
                />
              ) : (
                <Select
                  options={transferDoctorsList.map((ele) => ({
                    value: ele.doctorId,
                    label: `${ele.doctorName} ${
                      ele.vendorName ? `(${ele.vendorName})` : ''
                    } ( Monthly Target : ${
                      ele.dailyTarget === null ? 'Unset' : `${ele.dailyTarget}`
                    } Completed Count : ${ele.completeCount} )`,
                  }))}
                  onChange={this.onDoctorSelected}
                  isDisabled={this.state.transferLoading}
                  classNamePrefix="react-select"
                />
              )}
              <ButtonComponent>
                <Button
                  onTap={this.transferConsultation}
                  disabled={selectedDoctorIndex === -1}
                  loading={this.state.loading}
                >
                  <ButtonText>Transfer</ButtonText>
                </Button>
              </ButtonComponent>
            </Body>
          </ModalBody>
        </ModalContainer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorId: state.consults.activeConsult.doctorId,
  userId: state.consults.activeConsult.user.userId,
  labels: state.consults.activeConsult.labels,
  authToken: state.user.authToken,
  activeFilterName: state.info.activeFilterName,
  doctorType: state.user.doctorType,
  doctorsList: state.info.doctorsList,
  selectedStateFilter: state.info.selectedStateFilter
});

export default connect(mapStateToProps)(CloseConsultationModal);
