export const defaultState = {
  sponsors: [],
  verticals: [],
  prescriptions: [],
  pubnubChannelGroup: [],
  activeFilterName: 'My Cases',
  selectedStateFilter: null,
  searchText: '',
  filters: [
    { name: 'All', isSelected: false, admin: true },
    { name: 'Completed', isSelected: false, admin: true },
    { name: 'Open', isSelected: true, admin: true },
    { name: 'My Cases', isSelected: false },
    { name: 'My Open Cases', isSelected: false },
    { name: 'My Completed Cases', isSelected: false },
    { name: 'Followups', isSelected: false, admin: true },
    { name: 'Unattempted', isSelected: false },
    { name: 'Non workable', isSelected: false, admin: true },
    { name: 'QC TAT Surpass', isSelected: false, admin: true },
    {
      name: 'User rejected',
      isSelected: false,
      admin: true,
      qc: true,
      insurers: [
        'max',
        'reliance',
        'aegon',
        'ppmc',
        'godigit',
        'tata',
        'hdfc',
        'kotak',
      ],
    },
    {
      name: 'VideoCall cases',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: [
        'aegon',
        'ppmc',
        'lic',
        'abhi',
        'ipru',
        'absli',
        'absli-vv',
        'tata-mer',
        'mchi',
        'icicilbd',
      ],
    },
    {
      name: 'Policy Bazaar',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max', 'icicilbd'],
    },
    {
      name: 'Voice call cases',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: [
        'abhi',
        'ipru',
        'absli',
        'absli-vv',
        'max',
        'reliance',
        'hehi',
        'mchi',
        'godigit',
        'tata',
        'tata-mer',
        'hdfc',
        'icicilbd',
      ],
    },
    {
      name: 'PIVC cases',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['aegon', 'ppmc'],
    },
    {
      name: 'PIVV cases',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['aegon', 'ppmc'],
    },
    {
      name: 'User rejected',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max', 'reliance', 'aegon', 'ppmc', 'godigit', 'tata', 'hdfc'],
    },
    {
      name: 'Pending Case With Recording',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max'],
    },
    {
      name: 'Banca Case',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max', 'reliance', 'lic', 'aegon'],
    },
    {
      name: 'Instant Tele',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max'],
    },
    // {
    //   name: 'Instant Tele Missed Case',
    //   isSelected: false,
    //   admin: false,
    //   specificInsurer: true,
    //   insurers: ['max'],
    // },
    {
      name: 'TU INCOMPLETE',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max', 'ipru'],
    },
    // {
    //   name: 'Paytm Case',
    //   isSelected: false,
    //   admin: false,
    //   specificInsurer: true,
    //   insurers: ['aegon', 'ppmc'],
    // },
    // {
    //   name: 'Paytm Unattempted Case',
    //   isSelected: false,
    //   admin: false,
    //   specificInsurer: true,
    //   insurers: ['aegon', 'ppmc'],
    // },
    {
      name: 'Duplicate Case',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['max'],
    },
    {
      name: 'Discrepency Case',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['ipru'],
    },
    {
      name: 'Joint MER',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['aegon'],
    },
    {
      name: 'Grievance Case',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['aegon'],
    },
    {
      name: 'ON-HOLD',
      isSelected: false,
      admin: false,
      specificInsurer: false,
      insurers: [''],
    },
    {
      name: 'ZOOPER',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['aegon'],
    },
    {
      name: 'STATE',
      isSelected: false,
      admin: false,
      specificInsurer: true,
      insurers: ['mchi'],
      type: 'dropdown',
      value: null,
      dropdownValues: [
        'Kerala',
        'Tamilnadu',
        'Andhra Pradesh',
        'Telangana',
        'Karnataka'
      ],
    },
    // { name: 'Doctor Id', isSelected: false, isHidden: true }, // Make sure this filter always stays the last element in the array
  ],
  filterDoctorId: -1,
  selectedSponsorId: null,
  canPaginate: false,
  paginateToken: 2,
  fetchConsultsUrlForPagination: null,
  callRemarks: [],
  callMade: false,
  doctorsList: [],
  videoCallMade: false,
};

export default function info(state = defaultState, action) {
  switch (action.type) {
    case 'SET_INFO':
      return Object.assign({}, state, {
        sponsors: [
          { sponsorId: null, sponsorName: 'Select None', value: null },
          ...action.payload.sponsors,
        ],
        verticals: action.payload.verticals,
        prescriptions: action.payload.prescriptions,
        pubnubChannelGroup: action.payload.pubnubChannelGroup,
      });
    case 'TOGGLE_FILTER': {
      const filters = [...state.filters];
      if (filters[action.payload.index]) {
        filters[action.payload.index].isSelected =
          !filters[action.payload.index].isSelected;
      }
      return Object.assign({}, state, {
        selectedStateFilter: action.payload.ele,
        activeFilterName: filters[action.payload.index]
          ? filters[action.payload.index].name
          : 'Doctor Id',
        canPaginate: defaultState.canPaginate,
        paginateToken: defaultState.paginateToken,
        fetchConsultsUrlForPagination:
          defaultState.fetchConsultsUrlForPagination,
      });
    }
    case 'SET_FILTER_DOCTOR_ID': {
      return Object.assign({}, state, {
        filterDoctorId: action.payload,
      });
    }
    case 'RESET_CONSULTS': {
      return Object.assign({}, state, {
        canPaginate: defaultState.canPaginate,
        paginateToken: defaultState.paginateToken,
        fetchConsultsUrlForPagination:
          defaultState.fetchConsultsUrlForPagination,
      });
    }
    case 'RESET_FILTER': {
      return Object.assign({}, state, {
        activeFilterName: '',
      });
    }
    case 'SET_SELECTED_SPONSOR_ID': {
      return Object.assign({}, state, {
        selectedSponsorId: action.payload.selectedSponsorId,
      });
    }
    case 'SET_FETCH_CONSULTS_URL_FOR_PAGINATION': {
      return Object.assign({}, state, {
        fetchConsultsUrlForPagination: action.payload.url,
        canPaginate: true,
        paginateToken: 2,
      });
    }
    case 'ADD_CONSULTS': {
      const consultsArrayLength = action.payload.consults.length;
      return Object.assign({}, state, {
        canPaginate: consultsArrayLength > 0 ? true : false,
        paginateToken: consultsArrayLength > 0 ? state.paginateToken + 1 : 2,
      });
    }
    case 'SET_SEARCH_TEXT': {
      return Object.assign({}, state, {
        searchText: action.payload.searchText,
      });
    }
    case 'LOGOUT_USER': {
      return Object.assign({}, state, defaultState);
    }
    case 'SET_CALL_REMARKS': {
      return Object.assign({}, state, {
        callRemarks: action.payload,
      });
    }
    case 'CALL_MADE': {
      return Object.assign({}, state, {
        callMade: action.payload,
      });
    }
    case 'VIDEO_CALL_MADE': {
      return Object.assign({}, state, {
        videoCallMade: action.payload,
      });
    }
    case 'SET_DOCTORS_LIST': {
      return Object.assign({}, state, {
        doctorsList: action.payload,
      });
    }
    default:
      return state;
  }
}
