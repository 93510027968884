export function loginUser(phone, password, history) {
  return {
    type: 'LOGIN_USER',
    payload: {
      phone,
      password,
      history,
    },
  };
}

export function verifyUser(phone, otp, history) {
  return {
    type: 'VERIFY_USER',
    payload: {
      phone,
      otp,
      history,
    },
  };
}

export function setUserInfo(userInfo) {
  return {
    type: 'SET_USER_INFO',
    payload: {
      userInfo,
    },
  };
}

export function setAppState(state) {
  return {
    type: 'SET_APP_STATE',
    payload: state,
  };
}

export function setInfo(
  pubnubChannelGroup,
  verticals,
  sponsors,
  prescriptions,
) {
  return {
    type: 'SET_INFO',
    payload: {
      pubnubChannelGroup,
      verticals,
      sponsors,
      prescriptions,
    },
  };
}

export function setAuthToken(authToken) {
  return {
    type: 'SET_AUTH_TOKEN',
    payload: authToken,
  };
}

export function fetchConsults({
  authToken,
  filters,
  selectedSponsorId,
  searchText,
  doctorId,
  applicationIds,
}) {
  return {
    type: 'FETCH_CONSULTS',
    payload: {
      authToken,
      filters,
      selectedSponsorId,
      searchText,
      doctorId,
      applicationIds,
    },
  };
}

export function fetchConsultsOnPagination({
  authToken,
  paginateToken,
  fetchConsultsUrlForPagination,
  applicationIds,
}) {
  return {
    type: 'FETCH_CONSULTS_ON_PAGINATION',
    payload: {
      authToken,
      paginateToken,
      fetchConsultsUrlForPagination,
      applicationIds,
    },
  };
}

export function setConsults(consults) {
  return {
    type: 'SET_CONSULTS',
    payload: {
      consults,
    },
  };
}

export function addConsults(consults) {
  return {
    type: 'ADD_CONSULTS',
    payload: {
      consults,
    },
  };
}

export function selectConsult(consult) {
  return {
    type: 'SELECT_CONSULT',
    payload: {
      consult,
    },
  };
}

export function setActiveConsult(consult) {
  return {
    type: 'SET_ACTIVE_CONSULT',
    payload: {
      consult,
    },
  };
}

export function resetActiveConsult() {
  return {
    type: 'RESET_ACTIVE_CONSULT',
  };
}

export function showError(errorTitle, errorDesc) {
  return {
    type: 'SHOW_ERROR',
    payload: {
      hasError: true,
      errorTitle,
      errorDesc,
    },
  };
}

export function hideError() {
  return {
    type: 'HIDE_ERROR',
    payload: null,
  };
}

export function showSuccess(messageTitle, messageBody) {
  return {
    type: 'SHOW_SUCCESS',
    payload: {
      hasSuccess: true,
      messageTitle,
      messageBody,
    },
  };
}

export function hideSuccess() {
  return {
    type: 'HIDE_SUCCESS',
    payload: null,
  };
}

export function addChatMessage(chatMessage) {
  return {
    type: 'ADD_CHAT_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function sendMessage(chatMessage) {
  return {
    type: 'SEND_MESSAGE',
    payload: {
      chatMessage,
    },
  };
}

export function setTypingState(status) {
  return {
    type: 'SET_TYPING_STATE',
    payload: {
      status,
    },
  };
}

export function setChatInfo(chatInfo) {
  return {
    type: 'SET_CHAT_INFO',
    payload: {
      ...chatInfo,
    },
  };
}

export function setChatMessages(chatMessages) {
  console.log(chatMessages);
  return {
    type: 'SET_CHAT_MESSAGES',
    payload: {
      chatMessages,
    },
  };
}

export function resetChat() {
  return {
    type: 'RESET_CHAT',
  };
}

export function resetPrescription() {
  return {
    type: 'RESET_PRESCRIPTION',
  };
}

export function showFilterPane() {
  return {
    type: 'SHOW_FILTER_PANE',
  };
}

export function hideFilterPane() {
  return {
    type: 'HIDE_FILTER_PANE',
  };
}

export function toggleFilter(index, doctorId, ele) {
  return {
    type: 'TOGGLE_FILTER',
    payload: {
      index,
      doctorId,
      ele
    },
  };
}

export function resetFilter() {
  return {
    type: 'RESET_FILTER',
  };
}

export function setSelectedSponsorId(selectedSponsorId) {
  return {
    type: 'SET_SELECTED_SPONSOR_ID',
    payload: {
      selectedSponsorId,
    },
  };
}

export function setFetchConsultsUrlForPagination(url) {
  return {
    type: 'SET_FETCH_CONSULTS_URL_FOR_PAGINATION',
    payload: {
      url,
    },
  };
}

export function setPrescriptionId(prescriptionId) {
  return {
    type: 'SET_PRESCRIPTION_ID',
    payload: {
      prescriptionId,
    },
  };
}

export function addMedicines(medicines) {
  return {
    type: 'ADD_MEDICINES',
    payload: {
      medicines,
    },
  };
}

export function removeMedicine(medicine) {
  return {
    type: 'REMOVE_MEDICINES',
    payload: {
      medicine,
    },
  };
}

export function addLabTests(labTests) {
  return {
    type: 'ADD_LAB_TESTS',
    payload: {
      labTests,
    },
  };
}

export function removeLabTest(lab) {
  return {
    type: 'REMOVE_LAB_TEST',
    payload: {
      lab,
    },
  };
}

export function setTreatmentPlan(treatmentPlan) {
  return {
    type: 'SET_TREATMENT_PLAN',
    payload: {
      treatmentPlan,
    },
  };
}

export function setDoctorTyping(typing, doctorId) {
  return {
    type: 'SET_DOCTOR_TYPING',
    payload: {
      typing,
      doctorId,
    },
  };
}

export function setUnrepliedChannel(channel) {
  return {
    type: 'SET_UNREPLIED_CHANNEL',
    payload: channel,
  };
}

export function updateConsults(consultationId) {
  return {
    type: 'UPDATE_CONSULTS',
  };
}

export function closeConsult(userId, consultationId, reason, authToken) {
  return {
    type: 'CLOSE_CONSULT',
    payload: {
      userId,
      consultationId,
      reason,
      authToken,
    },
  };
}

export function logoutUser() {
  return {
    type: 'LOGOUT_USER',
  };
}

export function setSearchText(searchText) {
  return {
    type: 'SET_SEARCH_TEXT',
    payload: {
      searchText,
    },
  };
}

export function resetConsults() {
  return {
    type: 'RESET_CONSULTS',
  };
}

export function setCallRemarks(remarks) {
  return {
    type: 'SET_CALL_REMARKS',
    payload: remarks,
  };
}

export function setRelatives(relatives) {
  return {
    type: 'SET_RELATIVES',
    payload: relatives,
  };
}

export function callMade(flag) {
  return {
    type: 'CALL_MADE',
    payload: flag,
  };
}

export function videoCallMade(flag) {
  console.log('videoCallMade()');
  return {
    type: 'VIDEO_CALL_MADE',
    payload: flag,
  };
}

export function setDoctorsList(doctorsList) {
  return {
    type: 'SET_DOCTORS_LIST',
    payload: doctorsList,
  };
}

export function setFilterDoctorId(doctorId) {
  return {
    type: 'SET_FILTER_DOCTOR_ID',
    payload: doctorId,
  };
}

export function setProposerDetails(proposerDetails) {
  return {
    type: 'SET_PROPOSER_DETAILS',
    payload: proposerDetails,
  };
}

export function setLoading(bool) {
  return {
    type: 'SET_LOADING',
    payload: bool,
  };
}

export function userStatus(flag) {
  return {
    type: 'USER_STATUS',
    payload: flag,
  };
}

export function ipruAddCase(flag) {
  return {
    type: 'IPRU_ADD_CASE',
    payload: flag,
  };
}

export function updateCallId(callId) {
  return {
    type: 'UPDATE_CALL_ID',
    payload: callId,
  };
}

export function setWhatsappDeliveryStatus(status) {
  return {
    type: 'SET_WHATSAPP_DELIVERY_STATUS',
    payload: status,
  };
}

export function setOneConnectLinks(links) {
  return {
    type: 'SET_ONECONNECT_LINKS',
    payload: links,
  };
}

export function updateInsurer(insurer, authToken) {
  return {
    type: 'UPDATE_INSURER',
    payload: {
      insurer,
      authToken,
    },
  };
}

export function openJoinMessage(applicationNo, userName) {
  return {
    type: 'OPEN_JOIN_MESSAGE',
    payload: {
      applicationNo,
      userName
    }
  }
}
export function closeJoinMessage() {
  return {
    type: 'CLOSE_JOIN_MESSAGE',
  }
}

export function initiateVideoCall() {
  return {
    type: 'INITIATE_VIDEO_CALL'
  }
}
export function endVideoCall() {
  return {
    type: 'END_VIDEO_CALL'
  }
}
